import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { LOGO_IMAGE_URL } from '@lib/constants/imageUrls';
import { Icon } from '@components/@shared';

type BgColor = 'white' | 'black';

interface HeaderProps extends PropsWithChildren {
  bgColor: BgColor;
}

function Header({ children, bgColor }: HeaderProps) {
  return (
    <StyledHeader $bgColor={bgColor}>
      <HeaderInner>{children}</HeaderInner>
    </StyledHeader>
  );
}

function Logo() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  return (
    <LogoImageButton type="button" onClick={handleClick}>
      <img src={LOGO_IMAGE_URL} alt="logo" />
    </LogoImageButton>
  );
}

interface BackButtonProps {
  onGoBack?: () => void;
}

function BackButton({ onGoBack }: BackButtonProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onGoBack) {
      onGoBack();
      return;
    }

    window.history.back();
  };

  return (
    <BackIconButton type="button" onClick={handleClick}>
      <Icon name="ChevronLeftNew" width={30} height={30} fill="black" />
    </BackIconButton>
  );
}

export default Object.assign(Header, {
  Logo,
  BackButton,
});

interface StyledHeaderProps {
  $bgColor: BgColor;
}

const StyledHeader = styled.header<StyledHeaderProps>`
  padding: ${(props) => `${props.theme.spaces.sm} ${props.theme.spaces.md}`};
  width: 100%;
  height: ${(props) => props.theme.sizes.headerHeight};
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${(props) =>
    props.$bgColor === 'black'
      ? props.theme.colors.black
      : props.theme.colors.white};
  z-index: 1000;
`;

const HeaderInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.fonts.size.sm};
  font-weight: ${(props) => props.theme.fonts.weight.medium};

  & > span {
    max-width: 72%;
    text-align: center;
  }
`;

const LogoImageButton = styled.button`
  display: flex;
  align-items: center;
  height: 100%;

  & > img {
    height: 100%;
    width: auto;
  }
`;

const BackIconButton = styled.button`
  padding: 0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  /* Add a fallback for WebKit specific issues */
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    display: -webkit-flex;
  }
`;
