export const getOS = () => {
  const ua = navigator.userAgent;

  if (ua.includes('Windows')) {
    return 'Windows';
  } else if (ua.includes('Mac')) {
    return 'MacOS';
  } else if (ua.includes('Linux')) {
    return 'Linux';
  } else if (ua.includes('Android')) {
    return 'Android';
  } else if (ua.includes('iPhone') || ua.includes('iPad')) {
    return 'iOS';
  } else {
    return '기타 OS';
  }
};
