import { UserAnalysisData } from '@store/user/analysis';
import { z } from 'zod';
import { checkSchema } from './core';

const analysisFieldList = {
  brand: '브랜드 이름',
  product_id: '제품 ID',
  device: '디바이스',
  action: '액션',
  target_page: '타겟 페이지',
  ip: 'IP 주소',
};

const AnalysisDataSchema = z.object({
  brand: z.string().min(1),
  product_id: z.string().min(1),
  device: z.string().min(1),
  action: z.string().min(1),
  target_page: z.string().min(1),
  ip: z.string().min(1),
});

export const checkAnalysisDataSchema = (data: UserAnalysisData) => {
  return checkSchema(data, AnalysisDataSchema, analysisFieldList);
};
