import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { userAxios } from './@core';

/**
 * 서명 message 선택지 조회 API
 */
const fetchSignatureMessageList = async () => {
  const res = await userAxios.get('/product/analects/list');

  return res?.data;
};

export const useSignatureMessageList = () => {
  return useSuspenseQuery({
    queryKey: ['messageOptionList'],
    queryFn: () => fetchSignatureMessageList(),
  });
};

export interface SignatureDatas {
  productId: string | undefined;
  message: string;
  signatureImageFile: File | null;
}

/**
 * 서명 등록 API
 */
const uploadSignature = async (datas: SignatureDatas) => {
  const formData = new FormData();
  const { signatureImageFile, productId, message } = datas;
  if (!productId) {
    toast.error('유효하지 않은 제품 ID입니다.');
    return;
  }

  if (signatureImageFile) {
    formData.append('img', signatureImageFile);
  }
  formData.append('id', productId);
  formData.append('signtext', message);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const res = await userAxios.post('/product/signature-draw', formData, config);

  return res;
};

export const useSignatureUpload = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['signatureUpload'],
    mutationFn: (datas: SignatureDatas) => uploadSignature(datas),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['productDetail'] }),
  });
};
