import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { UserAnalysisData } from '@store/user/analysis';
import { userAxios } from './@core';

/**
 * 유저 IP 가져오는 API
 */
export const getUserIp = async () => {
  try {
    const res = await axios.get('https://checkip.amazonaws.com/');
    const data = res?.data?.trim();
    return data || '';
  } catch (error) {
    return '';
  }
};

/**
 * 유저 접속 데이터 수집 API
 */
const sendUserAnalysis = async (datas: UserAnalysisData) => {
  const res = await userAxios.post('/analysis', datas);

  return res;
};

export const useUserAnalysisSend = () => {
  return useMutation({
    mutationKey: ['userAnalysis'],
    mutationFn: (datas: UserAnalysisData) => sendUserAnalysis(datas),
  });
};
