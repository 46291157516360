import { PropsWithChildren } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const wrapperStyle = {
  width: 'inherit',
  height: '100%',
};

const contentStyle = {
  justifyContent: 'center',
  width: '100%',
  height: '100%',
};

export default function ImageZoomable({ children }: PropsWithChildren) {
  return (
    <TransformWrapper>
      <TransformComponent
        wrapperStyle={wrapperStyle}
        contentStyle={contentStyle}
      >
        {children}
      </TransformComponent>
    </TransformWrapper>
  );
}
