import { FallbackProps } from 'react-error-boundary';
import styled from 'styled-components';

export default function ErrorFallback({
  error,
  resetErrorBoundary,
}: FallbackProps) {
  return (
    <Container>
      <ErrorMessage>
        {error.response?.data?.result?.msg || '알 수 없는 에러가 발생했습니다.'}
      </ErrorMessage>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  z-index: 9999;
  overflow: hidden;
`;

const ErrorMessage = styled.h2`
  font-size: ${(props) => props.theme.fonts.size.xl};
  font-weight: ${(props) => props.theme.fonts.weight.medium};
  color: ${(props) => props.theme.colors.gray600};
  white-space: pre-wrap;
  text-align: center;
`;
