export const downloadFile = (url: string, fileName: string) => {
  // a 태그 생성
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  // a 태그 제거
  link.parentNode?.removeChild(link);
};

export const createLinkAndDownloadFile = (data: any, type?: string) => {
  const blob = new Blob([data], {
    type:
      type ||
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = window.URL.createObjectURL(blob);
  const fileName = 'qr_list.xlsx';
  downloadFile(url, fileName);
  window.URL.revokeObjectURL(url);
};
