import * as Icons from '@assets/icons';
import { theme } from '@lib/styles/theme';

export type IconName = keyof typeof Icons;

interface IconProps {
  name: IconName;
  stroke?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export default function Icon({
  name,
  stroke,
  fill = 'black',
  width = 24,
  height = 24,
}: IconProps) {
  const IconSvg = Icons[name];

  return (
    <IconSvg
      width={width}
      height={height}
      fill={theme.colors[fill]}
      {...(stroke && { stroke: theme.colors[stroke] })}
    />
  );
}
