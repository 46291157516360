import { allowScroll, preventScroll } from '@lib/utils';
import { useEffect } from 'react';
import styled from 'styled-components';

interface ProgressBarProps {
  percentage: number;
}

export default function ProgressBar({ percentage }: ProgressBarProps) {
  // 모달이 띄워졌을 때 스크롤 방지 (화면 고정)
  useEffect(() => {
    const prevScrollY = preventScroll();

    return () => {
      allowScroll(prevScrollY);
    };
  }, []);

  return (
    <Wrapper>
      <Container>
        <Percentage>
          {/* <span>Uploading...</span> */}
          <span>{percentage}%</span>
          {/* <span>Completed</span> */}
        </Percentage>
        <BarContainer>
          <BarBase />
          <BarGauge $width={percentage} />
        </BarContainer>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spaces.md};
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  /* align-content: center; */
  flex-wrap: wrap;
  /* background: ${(props) => props.theme.colors.modalBg}; */
  z-index: 9998;
  cursor: pointer;

  /* 스크롤 바 hidden */
  -ms-overflow-style: none;
  scrollbar-width: none;
  &&&::-webkit-scrollbar {
    display: none;
  }

  & > * {
    z-index: 2001;
    cursor: default;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 40%;
`;

const BarContainer = styled.div`
  position: relative;
  width: 280px;
  height: 20px;

  border: 2px solid ${(props) => props.theme.colors.gray900};
`;

const BarBase = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.gray200};
`;

interface BarProgressProps {
  $width: number;
}

const BarGauge = styled.div<BarProgressProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => `${props.$width}%`};
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.gray900};

  transform: ${(props) => `scaleX(${props.$width}%)`};

  transform-origin: 0% 50%;

  z-index: 1;

  transition: transform 200ms ease-in;
`;

const Percentage = styled.div`
  width: 100%;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;

  /* & span:first-child {
    font-size: 14px;
  } */
`;
