/**
 * base64 이미지 데이터를 File 객체로 변환해주는 함수
 *
 * @param dataUrl base64 포맷 이미지
 * @param filename 이미지 파일 이름
 * @returns {File} 이미지 파일
 */
export const dataURLtoFile = (
  dataUrl: string,
  filename: string
): File | undefined => {
  const arr = dataUrl.split(',');
  if (arr.length < 2) return;

  const mimeArr = arr[0].match(/:(.*?);/);
  if (!mimeArr || mimeArr.length < 2) return;

  const mime = mimeArr[1];
  const bstr = atob(arr[1]);

  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const convertFileToBase64 = async (file: File) => {
  function getBase64(file: File): Promise<string> {
    const reader = new FileReader();

    return new Promise((resolve) => {
      reader.onload = (ev: ProgressEvent<FileReader>) => {
        if (!ev.target) return;

        resolve(String(ev.target.result));
      };

      reader.readAsDataURL(file);
    });
  }

  return await getBase64(file);
};
