import { useState } from 'react';
import styled from 'styled-components';
import { Icon } from '@components/@shared';

interface CheckboxProps {
  isChecked?: boolean;
  onClickCheckbox?: () => void;
}

export default function Checkbox({
  isChecked,
  onClickCheckbox,
}: CheckboxProps) {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleCheckboxHovered = () => {
    setIsHovered(true);
  };

  const handleCheckboxUnhovered = () => {
    setIsHovered(false);
  };

  return (
    <div>
      <HiddenInput type="checkbox" />
      <CheckboxContainer
        type="button"
        onClick={onClickCheckbox}
        onMouseEnter={handleCheckboxHovered}
        onMouseLeave={handleCheckboxUnhovered}
        className="checkbox"
      >
        <Icon
          name={isChecked ? 'CheckboxCheckedNew' : 'CheckboxNew'}
          fill={isChecked ? ' black' : 'gray400'}
        />
      </CheckboxContainer>
    </div>
  );
}

const HiddenInput = styled.input`
  display: none;
`;

const CheckboxContainer = styled.button`
  display: flex;
  align-items: center;
`;
