export const convertDateFormat = (date: string) => {
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);

  return `${year}.${month}.${day}`;
};

export const toKoreanISO = (date: Date): string => {
  const offset = new Date().getTimezoneOffset() * 60000;
  const koreanDate = new Date(date.getTime() - offset);

  return koreanDate.toISOString();
};

export const convertToDateRangeLabel = (dates: string[]) => {
  if (!dates[0] && !dates[1]) {
    return '';
  }

  return dates.reduce((acc, curr) => {
    if (acc === '') {
      return acc + convertDateFormat(curr);
    }

    return acc + ' - ' + convertDateFormat(curr);
  }, '');
};

export const convertDateToNavLabelFormat = (date: Date) => {
  const koreanDate = toKoreanISO(date);
  const year = koreanDate.slice(0, 4);
  const month = koreanDate.slice(5, 7);

  return `${year}.${month}`;
};

export const calcDDay = (createdAt: string, days: number) => {
  const now = new Date().getTime();
  const start = new Date(createdAt).getTime();
  const passedTime = now - start;
  // 밀리초를 일로 변환 후 반올림
  const passedDay = Math.round(passedTime / (24 * 60 * 60 * 1000));
  const res = days - passedDay;

  return 'D' + (res < 0 ? '+' : '-') + Math.abs(res);
};
