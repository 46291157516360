import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIsMutating } from '@tanstack/react-query';
import { allowScroll, preventScroll } from '@lib/utils';
import { CircleSpinner } from '.';

export default function MutatingSpinner() {
  const [loading, setLoading] = useState<boolean>(false);
  const isMutating = useIsMutating();

  // 스크롤 방지
  useEffect(() => {
    const prevScrollY = preventScroll();

    return () => {
      allowScroll(prevScrollY);
    };
  }, []);

  // 200ms 이상 소요될 때만 렌더링
  useEffect(() => {
    if (!isMutating) {
      setLoading(false);
      return;
    }

    const timeoutId = setTimeout(() => setLoading(true), 200);

    return () => clearTimeout(timeoutId);
  }, [isMutating]);

  if (!loading) {
    return null;
  }

  return (
    <Container>
      <CircleSpinner />
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.modalBg};
  overflow: hidden;
  z-index: 9997;
`;
