import styled from 'styled-components';

interface TitleLabelProps {
  label: string;
}

export default function TitleLabel({ label }: TitleLabelProps) {
  return <Label>{label}</Label>;
}

const Label = styled.div`
  padding: 2px 10px;
  display: inline-block;
  font-size: 12px;
  border-radius: 100px;
  background-color: ${(props) => props.theme.colors.primary};
`;
