import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from '@components/@shared';

interface EmptyCardProps {
  onOpenModal: () => void;
}

export default function EmptyCard({ onOpenModal }: EmptyCardProps) {
  const { t } = useTranslation('user/common', {
    keyPrefix: 'idCard',
  });

  return (
    <Container type="button" onClick={onOpenModal}>
      <Icon name="Add" />
      <div>{t('create')}</div>
    </Container>
  );
}

const Container = styled.button`
  padding: 28px 40px;
  width: 100%;
  height: 203px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px dashed ${(props) => props.theme.colors.gray400};
  font-size: 12px;
  font-weight: 500;
`;
