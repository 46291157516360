import styled from 'styled-components';
import { Icon } from '@components/@shared';

interface ToggleButtonProps {
  label: string;
  isToggleOn: boolean;
  onSetToggle: () => void;
}

export default function ToggleButton({
  label,
  isToggleOn,
  onSetToggle,
}: ToggleButtonProps) {
  return (
    <Button type="button" onClick={onSetToggle} $isOn={isToggleOn}>
      <span>{label}</span>
      <Icon name="ChevronDown" fill="gray400" />
    </Button>
  );
}

interface ButtonProps {
  $isOn?: boolean;
}

const Button = styled.button<ButtonProps>`
  margin-top: 12px;
  padding: 10px;
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: ${(props) => props.theme.fonts.size.sm};
  border-radius: 8px;
  color: ${(props) => props.theme.colors.gray700};
  background-color: ${(props) => props.theme.colors.gray50};

  & > *:last-child {
    transform: ${(props) => (props.$isOn ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 300ms
      ${(props) => props.theme.animations.customCubicBezier};
    display: flex;
    align-items: center;
  }
`;
