import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { useAtom } from 'jotai';
import { ThemeProvider } from 'styled-components';
import { theme } from '@lib/styles/theme';
import GlobalStyles from '@lib/styles/global';
import { USER_PAGE_PATH } from '@lib/constants/user/common';
import { ADMIN_PAGE_PATH } from '@lib/constants/admin/common';
import { accessTokenAtomWithPersistence } from '@store/login';
import LayoutPage from '@pages/user/LayoutPage';
import {
  ErrorFallback,
  LoadingSpinner,
  MutatingSpinner,
  Toast,
} from '@components/@shared';
import PrivateRouteAdmin from './PrivateRouteAdmin';

const MainPage = lazy(() => import('./pages/user/MainPage'));
const DppPage = lazy(() => import('./pages/user/DppPage'));
const FreePickUpServicePage = lazy(
  () => import('./pages/user/FreePickUpServicePage')
);
const RepairServicePage = lazy(() => import('./pages/user/RepairServicePage'));
// Renewal
const HomePage = lazy(() => import('./pages/user/HomePage'));
const CarbonPage = lazy(() => import('./pages/user/CarbonPage'));
const BrandPage = lazy(() => import('./pages/user/BrandPage'));
const SettingPage = lazy(() => import('./pages/user/SettingPage'));

// [ADMIN]
const AdminLayoutPage = lazy(() => import('./pages/admin/LayoutPage'));
const AdminNotFoundPage = lazy(() => import('./pages/admin/NotFoundPage'));
const AdminLoginPage = lazy(() => import('./pages/admin/LoginPage'));
const AdminSignUpPage = lazy(() => import('./pages/admin/SignUpPage'));
const AdminSignUpResultPage = lazy(
  () => import('./pages/admin/SignUpPage/ResultPage')
);
const AdminDashboardPage = lazy(() => import('./pages/admin/DashboardPage'));
const AdminBrandListPage = lazy(() => import('./pages/admin/BrandListPage'));
const AdminBrandDetailPage = lazy(
  () => import('./pages/admin/BrandDetailPage')
);
const AdminBrandUploadPage = lazy(
  () => import('./pages/admin/BrandUploadPage')
);
const AdminBrandUpdatePage = lazy(
  () => import('./pages/admin/BrandUpdatePage')
);
const AdminProductImageListPage = lazy(
  () => import('./pages/admin/ProductImageListPage')
);
const AdminProductImageUploadPage = lazy(
  () => import('./pages/admin/ProductImageUploadPage')
);
const AdminCareLabelImageListPage = lazy(
  () => import('./pages/admin/CareLabelImageListPage')
);
const AdminCareLabelImageUploadPage = lazy(
  () => import('./pages/admin/CareLabelImageUploadPage')
);
const AdminDppInfoListPage = lazy(
  () => import('./pages/admin/DppInfoListPage')
);
const AdminDppInfoDetailPage = lazy(
  () => import('./pages/admin/DppInfoDetailPage')
);
const AdminDppInfoExcelUploadPage = lazy(
  () => import('./pages/admin/DppInfoExcelUploadPage')
);
const AdminDppInfoUploadPage = lazy(
  () => import('./pages/admin/DppInfoUploadPage')
);
const AdminDppInfoUpdatePage = lazy(
  () => import('./pages/admin/DppInfoUpdatePage')
);

export default function App() {
  const location = useLocation();
  const [accessToken] = useAtom(accessTokenAtomWithPersistence);

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, [location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Toast />
      <MutatingSpinner />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route path={USER_PAGE_PATH.index} element={<LayoutPage />}>
              <Route path={USER_PAGE_PATH.product.index}>
                <Route
                  path={USER_PAGE_PATH.product.main}
                  element={<HomePage />}
                />
                <Route
                  path={USER_PAGE_PATH.product.carbon}
                  element={<CarbonPage />}
                />
                <Route
                  path={USER_PAGE_PATH.product.brand}
                  element={<BrandPage />}
                />
                <Route
                  path={USER_PAGE_PATH.product.setting}
                  element={<SettingPage />}
                />
                {/* <Route
                 path={USER_PAGE_PATH.product.main}
                  element={<MainPage />}
                 /> */}
                <Route
                  path={USER_PAGE_PATH.product.dppInfo}
                  element={<DppPage />}
                />
                <Route
                  path={USER_PAGE_PATH.product.freePickUpService}
                  element={<FreePickUpServicePage />}
                />
                <Route
                  path={USER_PAGE_PATH.product.repairService}
                  element={<RepairServicePage />}
                />
              </Route>
            </Route>

            {/* Admin */}
            {/* Admin - 로그인 */}
            <Route
              path={ADMIN_PAGE_PATH.login.index}
              element={<AdminLoginPage />}
            />
            {/* Admin - 회원가입 */}
            <Route
              path={ADMIN_PAGE_PATH.signUp.index}
              element={<AdminSignUpPage />}
            />
            <Route
              path={ADMIN_PAGE_PATH.signUp.result}
              element={<AdminSignUpResultPage />}
            />
            <Route path={ADMIN_PAGE_PATH.index} element={<AdminLayoutPage />}>
              <Route index element={<AdminNotFoundPage />} />
              {/* Admin - 대시보드 */}
              <Route path={ADMIN_PAGE_PATH.dashboard.index}>
                <Route
                  path={ADMIN_PAGE_PATH.dashboard.list}
                  element={<AdminDashboardPage />}
                />
              </Route>
              {/* Admin - 브랜드 정보 */}
              <Route path={ADMIN_PAGE_PATH.brand.index}>
                <Route
                  path={ADMIN_PAGE_PATH.brand.list}
                  element={
                    <PrivateRouteAdmin isAuthenticated={!!accessToken}>
                      <AdminBrandListPage />
                    </PrivateRouteAdmin>
                  }
                />
                <Route
                  path={ADMIN_PAGE_PATH.brand.detail}
                  element={
                    <PrivateRouteAdmin isAuthenticated={!!accessToken}>
                      <AdminBrandDetailPage />
                    </PrivateRouteAdmin>
                  }
                />
                <Route
                  path={ADMIN_PAGE_PATH.brand.upload}
                  element={<AdminBrandUploadPage />}
                />
                <Route
                  path={ADMIN_PAGE_PATH.brand.update}
                  element={<AdminBrandUpdatePage />}
                />
              </Route>
              {/* Admin - 제품 이미지 */}
              <Route path={ADMIN_PAGE_PATH.productImage.index}>
                <Route
                  path={ADMIN_PAGE_PATH.productImage.list}
                  element={
                    <PrivateRouteAdmin isAuthenticated={!!accessToken}>
                      <AdminProductImageListPage />
                    </PrivateRouteAdmin>
                  }
                />
                <Route
                  path={ADMIN_PAGE_PATH.productImage.upload}
                  element={
                    <PrivateRouteAdmin isAuthenticated={!!accessToken}>
                      <AdminProductImageUploadPage />
                    </PrivateRouteAdmin>
                  }
                />
              </Route>
              {/* Admin - 케어라벨 이미지 */}
              <Route path={ADMIN_PAGE_PATH.careLabelImage.index}>
                <Route
                  path={ADMIN_PAGE_PATH.careLabelImage.list}
                  element={
                    <PrivateRouteAdmin isAuthenticated={!!accessToken}>
                      <AdminCareLabelImageListPage />
                    </PrivateRouteAdmin>
                  }
                />
                <Route
                  path={ADMIN_PAGE_PATH.careLabelImage.upload}
                  element={
                    <PrivateRouteAdmin isAuthenticated={!!accessToken}>
                      <AdminCareLabelImageUploadPage />
                    </PrivateRouteAdmin>
                  }
                />
              </Route>
              {/* Admin - DPP 정보 */}
              <Route path={ADMIN_PAGE_PATH.dppInfo.index}>
                <Route
                  path={ADMIN_PAGE_PATH.dppInfo.list}
                  element={
                    <PrivateRouteAdmin isAuthenticated={!!accessToken}>
                      <AdminDppInfoListPage />
                    </PrivateRouteAdmin>
                  }
                />
                <Route
                  path={ADMIN_PAGE_PATH.dppInfo.detail}
                  element={
                    <PrivateRouteAdmin isAuthenticated={!!accessToken}>
                      <AdminDppInfoDetailPage />
                    </PrivateRouteAdmin>
                  }
                />
                <Route
                  path={ADMIN_PAGE_PATH.dppInfo.excelUpload}
                  element={
                    <PrivateRouteAdmin isAuthenticated={!!accessToken}>
                      <AdminDppInfoExcelUploadPage />
                    </PrivateRouteAdmin>
                  }
                />
                <Route
                  path={ADMIN_PAGE_PATH.dppInfo.upload}
                  element={
                    <PrivateRouteAdmin isAuthenticated={!!accessToken}>
                      <AdminDppInfoUploadPage />
                    </PrivateRouteAdmin>
                  }
                />
                <Route
                  path={ADMIN_PAGE_PATH.dppInfo.update}
                  element={
                    <PrivateRouteAdmin isAuthenticated={!!accessToken}>
                      <AdminDppInfoUpdatePage />
                    </PrivateRouteAdmin>
                  }
                />
              </Route>
              <Route path="*" element={<AdminNotFoundPage />} />
            </Route>
            {/* 404 Not Found */}
            <Route path="*" element={<div>404 Not Found</div>} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
