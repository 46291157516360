import { atom } from 'jotai';
import { ServiceType } from '@components/user/RepairService/RequestStep/StepContentTwo';

export const currStepAtom = atom<number>(1);
currStepAtom.onMount = (setAtom) => {
  return () => {
    setAtom(1);
  };
};

export const productCategoryAtom = atom<keyof ServiceType>('');

export const serviceTypesAtom = atom<string[]>([]);

export const productImagesAtom = atom<File[]>([]);

export const serviceRequestMsgAtom = atom<string>('');

const initDeliveryAddressData = {
  name: '',
  phone_number: '',
  address: '',
  address_detail: '',
  delivery_request_msg: '',
};

export type DeliveryAddress = typeof initDeliveryAddressData;

export const deliveryAddressAtom = atom<DeliveryAddress>(
  initDeliveryAddressData
);

export const deliveryTypeAtom = atom<string>('');

export const servicePriceAtom = atom<number>(0);
export const deliveryPriceAtom = atom<number>(0);
export const payAmountAtom = atom<number>(
  (get) => get(servicePriceAtom) + get(deliveryPriceAtom)
);

export const serviceRequestDataAtom = atom<any>((get) => {
  const product_category = get(productCategoryAtom);
  const service_types = get(serviceTypesAtom);
  const product_images = get(productImagesAtom);
  const request_msg = get(serviceRequestMsgAtom);
  const delivery_address = get(deliveryAddressAtom);
  const delivery_type = get(deliveryTypeAtom);
  const pay_amount = get(payAmountAtom);

  return {
    product_category,
    service_types,
    product_images,
    request_msg,
    delivery_address,
    delivery_type,
    pay_amount,
  };
});

const initErrorMsg = {
  inputName: '',
  message: '',
};

export type ErrorMsg = typeof initErrorMsg;

export const errorMsgAtom = atom(initErrorMsg);
errorMsgAtom.onMount = (setAtom) => {
  return () => {
    setAtom(initErrorMsg);
  };
};

/**
 * 새로운 선택란 추가해주는 함수 (write-only atom)
 */
export const initServiceRequestDataAtom = atom(null, (get, set) => {
  set(productCategoryAtom, '');
  set(serviceTypesAtom, []);
  set(productImagesAtom, []);
  set(serviceRequestMsgAtom, '');
  set(deliveryAddressAtom, initDeliveryAddressData);
  set(deliveryTypeAtom, '');
  set(servicePriceAtom, 0);
  set(deliveryPriceAtom, 0);
  set(errorMsgAtom, initErrorMsg);
});
