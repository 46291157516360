import styled from 'styled-components';
import { theme } from '@lib/styles/theme';

const gradientStartColor = '#8EAFC7';
const gradientEndColor = '#B4CBDC';

interface LoadingProps {
  size?: number;
  sw?: number;
}

export default function CircleSpinner({ size = 48, sw = 8 }: LoadingProps) {
  return (
    <svg
      width={size}
      height={size}
      x={size}
      y={size}
      viewBox={`0 0 ${size} ${size}`}
    >
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor={gradientStartColor} />
          <stop offset="100%" stopColor={gradientEndColor} />
        </linearGradient>
      </defs>
      <BaseCircle
        cx={size / 2}
        cy={size / 2}
        r={(size - sw) / 2}
        fill="none"
        stroke={gradientEndColor}
        strokeWidth={sw}
      />
      <LoadingCircle
        cx={size / 2}
        cy={size / 2}
        r={(size - sw) / 2}
        fill="none"
        stroke="url(#gradient)"
        strokeWidth={sw}
      />
    </svg>
  );
}

const BaseCircle = styled.circle`
  stroke-dashoffset: 220;
`;

const LoadingCircle = styled.circle`
  @keyframes circle_loading {
    0% {
      stroke-dashoffset: 44;
    }

    100% {
      stroke-dashoffset: 220;
    }
  }

  animation: 1s linear 1ms infinite circle_loading;
  stroke-dasharray: 100, 76;
  stroke-dashoffset: 44;
  stroke-linecap: round;
  fill: none;
`;
