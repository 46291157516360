import { PropsWithChildren, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useProductDetail } from '@lib/apis/user';
import { checkAnalysisDataSchema } from '@lib/apis/user/@schema/analysis';
import { useUserAnalysisSend } from '@lib/apis/user/analysis';
import { USER_NAV_LIST } from '@lib/constants/user/common';
import { brandNameAtom, productIdAtom } from '@store/user';
import { targetPageAtom, userAnalysisDataAtom } from '@store/user/analysis';

export default function AnalysisPage({ children }: PropsWithChildren) {
  const location = useLocation();
  const { productId } = useParams();
  const [, setBrandName] = useAtom(brandNameAtom);
  const [, setProductId] = useAtom(productIdAtom);
  const [, setTargetPage] = useAtom(targetPageAtom);
  const [userAnalysisData] = useAtom(userAnalysisDataAtom);
  const { data: productDetail } = useProductDetail(productId);
  const { mutate: sendUserAnalysis } = useUserAnalysisSend();

  // 브랜드 이름, 제품 ID 저장
  useEffect(() => {
    if (!productDetail) return;

    setBrandName(productDetail.brand.brand_name);
    setProductId(productDetail.id);
  }, [productDetail]);

  // 타겟 페이지 저장
  useEffect(() => {
    if (!productId) return;

    const slugStartIdx = 9 + productId.length + 1;
    const slug = location.pathname.slice(slugStartIdx);
    const currPage =
      USER_NAV_LIST.find((page) => page.path === slug)?.labelKey || '';
    setTargetPage(currPage);
  }, [productId, location.pathname]);

  // 유효성 검사 후 데이터 전송
  useEffect(() => {
    const data = { ...userAnalysisData };
    const res = checkAnalysisDataSchema(data);
    if (!res.success) return;

    sendUserAnalysis(data);
  }, [userAnalysisData]);

  return children;
}
