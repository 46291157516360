import { decrypt } from '@lib/utils/decrypt';
import axios, { AxiosInstance } from 'axios';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const CLIENT_BASE_URL = process.env.REACT_APP_CLIENT_BASE_URL;
export const AES_KEY = process.env.REACT_APP_AES_KEY;

export const userAxios: AxiosInstance = axios.create({
  baseURL: `${API_BASE_URL}`,
});

userAxios.interceptors.response.use(
  // 복호화
  (res) => {
    const data = res?.data?.result?.data;
    if (!data?.ed) {
      return res?.data?.result || res?.data || res;
    }

    const decryptedData = decrypt(data.ed, AES_KEY);
    const nextRes = { ...res, data: decryptedData };

    return nextRes;
  },
  async (err) => {
    return Promise.reject(err);
  }
);
