const phoneNumberRegex = /[^0-9]/g;

export const removeNaN = (value: string) => {
  const validateValue = value.replace(phoneNumberRegex, '');

  if (validateValue !== value) {
    alert('숫자만 입력해주세요.');
  }

  return value.replace(phoneNumberRegex, '');
};
