const IMAGE_STORAGE_BASE_URL = 'https://img.careid.xyz/icons';
// 로고
export const LOGO_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/logo.svg`;
export const LOGO_IMAGE_BLACK_URL = `${IMAGE_STORAGE_BASE_URL}/logo-black.png`;

// 메인 페이지 ReduceCarbon 아이콘 이미지
export const UPGRADE_ICON_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/upgrade-image.png`;
export const RECYCLE_ICON_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/recycle-image.png`;
export const SCISSOR_ICON_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/scissor-image.png`;
export const LAPTOP_ICON_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/laptop-image.png`;

// DPP 페이지 DppDescription 이미지
export const DPP_DESCRIPTION_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/dpp-web-image.png`;
export const DPP_DESCRIPTION_EN_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/dpp-web-image--en.png`;

export const BRAND_LOGO_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/LOGO+555.svg`;

// 수선 info 모달 이미지
export const REPAIR_INFO_STRING_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/repair-info-string.png`;
export const REPAIR_INFO_BUTTON_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/repair-info-button.png`;
export const REPAIR_INFO_STITCH_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/repair-info-stitch.png`;
export const REPAIR_INFO_REDYING_PARTIAL_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/repair-info-redying-partial.png`;
export const REPAIR_INFO_REDYING_ENTIRE_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/repair-info-redying-entire.png`;
export const REPAIR_INFO_UNIT_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/repair-info-unit.png`;

// TGDOT 영상
export const TGDOT_VIDEO_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_2.mp4`;
export const TGDOT_VIDEO_THUMB_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_thumb_2.webp`;

// TGDOT 브랜드 소개 이미지
export const TGDOT_MAIN_IMAGE_URL = `${IMAGE_STORAGE_BASE_URL}/tgdot-corp.webp`;
// TGDOT Tony Collection 이미지
export const TGDOT_COLLECTION_TONY_IMAGE_1_URL = `${IMAGE_STORAGE_BASE_URL}/tgdot_tony_01.webp`;
export const TGDOT_COLLECTION_TONY_IMAGE_2_URL = `${IMAGE_STORAGE_BASE_URL}/tgdot_tony_02.webp`;
export const TGDOT_COLLECTION_TONY_IMAGE_3_URL = `${IMAGE_STORAGE_BASE_URL}/tgdot_tony_03.webp`;
export const TGDOT_COLLECTION_TONY_IMAGE_4_URL = `${IMAGE_STORAGE_BASE_URL}/tgdot_tony_04.webp`;
// TGDOT Tony Collection 제품 이미지
export const TGDOT_PRODUCT_HOODIE_BK_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_hoodie_BK.webp`;
export const TGDOT_PRODUCT_HOODIE_GR_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_hoodie_GR.webp`;
export const TGDOT_PRODUCT_PANTS_BK_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_pants_BK1.webp`;
export const TGDOT_PRODUCT_PANTS_GR_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_pants_BK2.webp`;
export const TGDOT_PRODUCT_LONG_SLEEVES_BK_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_longsleeves_2.webp`;
export const TGDOT_PRODUCT_LONG_SLEEVES_BG_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_longsleeves_1.webp`;
export const TGDOT_PRODUCT_SHIRTS_JK_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_shirts_JK.webp`;
export const TGDOT_PRODUCT_CAP_DEV_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_cap_DEV.webp`;
export const TGDOT_PRODUCT_CAP_AI_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_cap_AI.webp`;
export const TGDOT_PRODUCT_CAP_BK_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_cap_BK.webp`;
export const TGDOT_PRODUCT_CAP_BL_URL = `${IMAGE_STORAGE_BASE_URL}/TGDOT_cap_BL.webp`;
