import { atom } from 'jotai';

export const accessTokenAtom = atom<string>(
  localStorage.getItem('accessToken') ?? ''
);

export const accessTokenAtomWithPersistence = atom(
  (get) => get(accessTokenAtom),
  (get, set, newAccessToken?: string) => {
    if (newAccessToken) {
      set(accessTokenAtom, newAccessToken);
      localStorage.setItem('accessToken', newAccessToken);
    } else {
      set(accessTokenAtom, '');
      localStorage.removeItem('accessToken');
    }
  }
);

export const roleAtom = atom<string>(localStorage.getItem('role') ?? '');

export const roleAtomWithPersistence = atom(
  (get) => get(roleAtom),
  (get, set, newRole?: string) => {
    if (newRole) {
      set(roleAtom, newRole);
      localStorage.setItem('role', newRole);
    } else {
      set(roleAtom, '');
      localStorage.removeItem('role');
    }
  }
);

export const chargeBrandAtom = atom<string>(
  localStorage.getItem('chargeBrand') ?? ''
);

export const chargeBrandAtomWithPersistence = atom(
  (get) => get(chargeBrandAtom),
  (get, set, newChargeBrand?: string) => {
    if (newChargeBrand) {
      set(chargeBrandAtom, newChargeBrand);
      localStorage.setItem('chargeBrand', newChargeBrand);
    } else {
      set(chargeBrandAtom, '');
      localStorage.removeItem('chargeBrand');
    }
  }
);
