import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { USER_NAV_LIST } from '@lib/constants/user/common';
import { Icon } from '@components/@shared';

export default function Navigator() {
  const { productId } = useParams();
  const location = useLocation();
  const [currPathLabel, setCurrPathLabel] = useState<string>('');

  useEffect(() => {
    const lastIndexOfSlash = location.pathname.lastIndexOf('/');
    const pathName = location.pathname.slice(lastIndexOfSlash + 1);
    const currPath = USER_NAV_LIST.find((nav) => nav.path === pathName);
    setCurrPathLabel(currPath?.labelKey || USER_NAV_LIST[0].labelKey);
  }, [location.pathname]);

  return (
    <NavigatorWrapper>
      <NavigatorContainer>
        <NavItem
          to={`/product/${productId}`}
          $isActive={currPathLabel === 'Product'}
        >
          <Icon
            name={
              currPathLabel === 'Product' ? 'TabClothingActive' : 'TabClothing'
            }
          />
          <span>Product</span>
        </NavItem>
        <NavItem
          to={`/product/${productId}/carbon`}
          $isActive={currPathLabel === 'Carbon'}
        >
          <Icon
            name={currPathLabel === 'Carbon' ? 'TabCloudActive' : 'TabCloud'}
          />
          <span>Carbon</span>
        </NavItem>
        <div></div>
        <CenterIconWrapper
          to={`/product/${productId}/dpp`}
          $isActive={currPathLabel === 'DPP'}
        >
          <CenterIconContainer>
            <Icon name="TabGlobe" fill="white" />
          </CenterIconContainer>
          <span>DPP</span>
        </CenterIconWrapper>
        <NavItem
          to={`/product/${productId}/brand`}
          $isActive={currPathLabel === 'Brand'}
        >
          <Icon name={currPathLabel === 'Brand' ? 'TabTagActive' : 'TabTag'} />
          <span>Brand</span>
        </NavItem>
        <NavItem
          to={`/product/${productId}/setting`}
          $isActive={currPathLabel === 'Setting'}
        >
          <Icon
            name={
              currPathLabel === 'Setting' ? 'TabSettingActive' : 'TabSetting'
            }
          />
          <span>Setting</span>
        </NavItem>
      </NavigatorContainer>
    </NavigatorWrapper>
  );
}

const NavigatorWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const NavigatorContainer = styled.div`
  padding: 0 14px;
  position: relative;
  height: 80px;
  background-color: white;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border-top: 1px solid ${(props) => props.theme.colors.gray100};
`;

interface NavItemProps {
  $isActive: boolean;
}

const NavItem = styled(Link)<NavItemProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: ${(props) => (props.$isActive ? '#4A90E2' : props.theme.colors.black)};
  font-size: 12px;
`;

const CenterIconWrapper = styled(Link)<NavItemProps>`
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.gray100};
  z-index: 10;
  color: ${(props) => (props.$isActive ? '#4A90E2' : props.theme.colors.black)};

  & > div:first-of-type {
    background-color: ${(props) =>
      props.$isActive ? '#99CAFF' : props.theme.colors.black};
  }

  /* Bar와 겹치는 부분의 테두리를 가리기 위한 스타일 */
  &::before {
    content: '';
    position: absolute;
    top: 39px;
    width: 80px;
    height: 80px;
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid white;
    z-index: -1;
  }

  /* Bar와 겹치는 부분의 테두리를 가리기 위한 스타일 */
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.white};
    z-index: -1;
  }
`;

const CenterIconContainer = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 50%;
  position: relative;

  & > *:first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
