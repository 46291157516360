import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';

i18n
  // 원격(remote) 서버에서 번역 pre-load 기능 제공
  // (/public/locales 디렉토리에서 다국어 json 파일 관리)
  .use(HttpBackend)
  // 브라우저의 언어 설정을 기반의 사용자 언어 자동 검출 기능 제공
  .use(LanguageDetector)
  // react 프로젝트용의 i18next를 initialize
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem('i18nextLng') || 'ko',
    // lng: 'en',
    fallbackLng: 'ko',
    debug: process.env.NODE_ENV === 'development',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // 폴더 구조에 맞게 설정
    },
    // 사용할 네임스페이스
    ns: [
      // user
      'user/common',
      'user/main',
      'user/dpp',
      'user/repair',
      'user/pickup',
      'user/signature',
      // amdin
      'admin/common',
      'admin/brandList',
      'admin/brandUpload',
      'admin/careLabelImage',
      'admin/careLabelImageUpload',
      'admin/dashboard',
      'admin/dppList',
      'admin/dppExcelUpload',
      'admin/dppUpload',
      'admin/login',
      'admin/productImage',
      'admin/productImageUpload',
    ],
    // 기본 네임스페이스
    defaultNS: 'user/common',
    interpolation: {
      // HTML 태그나 특수 문자열 처리 따로 하지 않도록 설정
      escapeValue: false,
    },
    react: {
      // Suspense를 사용하여 lazy loading
      useSuspense: true,
    },
  });

export default i18n;
