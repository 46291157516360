import { z } from 'zod';

export interface IndexedSignatureTypeData {
  [key: string]: any;
}

export type LanguageType = 'en' | 'ko';
type ErrorType =
  | 'invalid_type_number'
  | 'invalid_type'
  | 'too_small_number'
  | 'too_small'
  | 'invalid_string'
  | 'default';

export type ErrorMessagesType<T extends string> = Record<
  LanguageType,
  Record<T, string>
>;

const errorMessages: ErrorMessagesType<ErrorType> = {
  ko: {
    invalid_type_number: `'{fieldName}'은(는) 숫자로 입력해주세요.`,
    invalid_type: `'{fieldName}'에 유효한 값을 입력해주세요.`,
    too_small_number: `'{fieldName}'은(는) 0 이상의 숫자를 입력해주세요.`,
    too_small: `'{fieldName}'은(는) 필수 입력 값입니다.`,
    invalid_string: `'{fieldName}'의 형식을 확인해주세요.`,
    default: '알 수 없는 에러가 발생했습니다.\n관리자에게 문의해주세요.',
  },
  en: {
    invalid_type_number: `'{fieldName}' must be a number.`,
    invalid_type: `Please enter a valid value for '{fieldName}'.`,
    too_small_number: `'{fieldName}' must be greater than or equal to 0.`,
    too_small: `'{fieldName}' is required.`,
    invalid_string: `Please check the format of '{fieldName}'.`,
    default: 'An unknown error occurred.\nPlease contact the administrator.',
  },
};

export const getLanguage = (): LanguageType => {
  const lang = localStorage.getItem('i18nextLng');
  return lang === 'ko' ? 'ko' : 'en'; // 기본값은 'en'
};

export const getTranslatedErrorMessage = <T extends string>(
  errorMessages: ErrorMessagesType<T>,
  errorType: T,
  fieldName?: string
): string => {
  const lang = getLanguage();
  const message = errorMessages[lang][errorType];

  return fieldName ? message.replace('{fieldName}', fieldName) : message;
};

const errorMap =
  (fieldList: IndexedSignatureTypeData): z.ZodErrorMap =>
  (error, ctx) => {
    let fieldDepth = error.path.length;
    let fieldName =
      error.path[0] in fieldList
        ? fieldList[error.path[0] as keyof typeof fieldList]
        : error.path[0];
    let currPathNumber = 1;

    while (fieldDepth) {
      const currPathName = error.path[currPathNumber];

      if (typeof currPathName === 'string' && fieldName[currPathName]) {
        fieldName = fieldName[currPathName];
      }

      currPathNumber++;
      fieldDepth--;
    }

    switch (error.code) {
      case z.ZodIssueCode.invalid_type:
        return {
          message:
            error.expected === 'number'
              ? getTranslatedErrorMessage(
                  errorMessages,
                  'invalid_type_number',
                  fieldName
                )
              : getTranslatedErrorMessage(
                  errorMessages,
                  'invalid_type',
                  fieldName
                ),
        };
      case z.ZodIssueCode.too_small:
        return {
          message:
            error.type === 'number'
              ? getTranslatedErrorMessage(
                  errorMessages,
                  'too_small_number',
                  fieldName
                )
              : getTranslatedErrorMessage(
                  errorMessages,
                  'too_small',
                  fieldName
                ),
        };
      case z.ZodIssueCode.invalid_string:
        return {
          message: getTranslatedErrorMessage(
            errorMessages,
            'invalid_string',
            fieldName
          ),
        };
      default:
        return {
          message:
            ctx.defaultError ||
            getTranslatedErrorMessage(errorMessages, 'default'),
        };
    }
  };

export type SchemaCheckReturnType = {
  success: boolean;
  fieldName?: string;
  errorMessage?: string;
};

export const checkSchema = (
  data: IndexedSignatureTypeData | FormData,
  schema: z.ZodObject<any>,
  fieldList?: IndexedSignatureTypeData
): SchemaCheckReturnType => {
  const dataObj =
    data instanceof FormData ? Object.fromEntries(data.entries()) : data;
  const res = schema.safeParse(dataObj, {
    // fieldList가 있으면 custom error map 사용
    errorMap: fieldList ? errorMap(fieldList) : undefined,
  });

  if (res.error) {
    const fieldErrors = Object.entries(res.error.flatten().fieldErrors);

    const errorInputName = res.error.issues.at(0)?.path.at(-1) || '';
    const errorMessage =
      fieldErrors?.at(0)?.at(1) ||
      getTranslatedErrorMessage(errorMessages, 'default');

    return {
      success: false,
      fieldName: Array.isArray(errorInputName)
        ? errorInputName[0]
        : errorInputName,
      errorMessage: Array.isArray(errorMessage)
        ? errorMessage[0]
        : errorMessage,
    };
  }

  return { success: res.success };
};
