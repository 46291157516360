import styled from 'styled-components';
import { convertDateFormat } from '@lib/utils';
import { DppLevelFiveData } from '@customTypes/user';

interface ProductIdCardProps {
  productInfo: DppLevelFiveData;
}

export default function ProductIdCard({ productInfo }: ProductIdCardProps) {
  return (
    <Wrapper>
      <Container>
        <LeftContents>
          <InfoContainer>
            <div>
              <ProductName>{productInfo.name}</ProductName>
              <CategoryAndSkuNumber>
                <span>{productInfo.category}</span>
                <span>/</span>
                <span>{productInfo.sku_index}</span>
                {productInfo.signature?.created_at && (
                  <>
                    <span>/</span>
                    <span>{productInfo.confection_index}</span>
                  </>
                )}
              </CategoryAndSkuNumber>
            </div>
            <UserInfoContainer>
              <div>{productInfo.signature?.name || 'Name'}</div>
              <div>{productInfo.signature?.phone || 'Phone Number'}</div>
              <div>{productInfo.signature?.email || 'E-mail'}</div>
            </UserInfoContainer>
            <HomepageUrl>
              <span>WEB</span>
              <span>tgdot.kr</span>
            </HomepageUrl>
            <DateAndId>
              <div>
                <span>ISSUE DATE</span>
                <span>
                  {productInfo.signature?.created_at
                    ? convertDateFormat(productInfo.signature.created_at)
                    : 'YYYY.MM.DD'}
                </span>
              </div>
              <div>
                <span>ID NUMBER</span>
                <IdNumber>
                  {productInfo.signature?.created_at ? productInfo.id : '-'}
                </IdNumber>
              </div>
            </DateAndId>
          </InfoContainer>
          <SignatureContainer>
            <span>SIGNATURE AREA</span>
            {productInfo.signature?.sign_img_url && (
              <SignautureImage
                src={productInfo.signature.sign_img_url}
                alt="sign image"
              />
            )}
          </SignatureContainer>
          {/*  */}
        </LeftContents>
        <RightContents>
          <ProductImageContainer>
            <img src={productInfo.img_1 || ''} />
          </ProductImageContainer>
          <BrandLogoImagecontainer>
            <img src={productInfo.brand.img_logo_url} />
          </BrandLogoImagecontainer>
          {/* <BrandLogoImage src={productInfo.brand.img_logo_url} /> */}
        </RightContents>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  background-color: ${(props) => props.theme.colors.white};
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 14px;
  font-family: 'prestige-elite-std', monospace;
  font-size: 10px;
  font-weight: 700;
  line-height: 0.8em;
`;

const LeftContents = styled.div`
  width: 100%;
  flex-shrink: 1;
`;

const RightContents = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ProductName = styled.h3`
  font-size: 16px;
  line-height: 1.1em;
`;

const CategoryAndSkuNumber = styled.div`
  margin-top: 4px;
  display: flex;
  gap: 8px;
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const HomepageUrl = styled.div`
  display: flex;
  gap: 8px;
`;

const DateAndId = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;

  & > div {
    display: flex;
  }

  & > div:first-child {
    flex-shrink: 1;
  }

  /* title (ex. ISSUE DATE) */
  & > div > span:first-of-type {
    /* flex-shrink: 0; */
    margin-right: 4px;
    font-size: 6px;
    line-height: 1em;
  }
`;

const IdNumber = styled.span`
  word-break: break-all;
`;

const SignatureContainer = styled.div`
  margin-top: 26px;
  padding-bottom: 4px;
  width: 100%;
  position: relative;
  font-size: 6px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray100};
`;

const SignautureImage = styled.img`
  display: inline-block;
  position: absolute;
  left: 98px;
  bottom: 0;
  max-width: 60px;
  max-height: 28px;
`;

const ProductImageContainer = styled.div`
  width: 94px;
  height: 110px;

  & > img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

const BrandLogoImagecontainer = styled.div`
  width: 51px;
  height: 58px;
  display: flex;

  & > img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;
