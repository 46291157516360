import { atom } from 'jotai';
import { brandNameAtom, productIdAtom } from './common';
import { getUserIp } from '@lib/apis/user/analysis';
import { getOS } from '@lib/utils/analysis';

export interface UserAnalysisData {
  brand: string;
  product_id: string;
  device: string;
  action: string;
  target_page: string;
  ip: string;
}

export const userIpAtom = atom<string>('');
userIpAtom.onMount = (setAtom) => {
  const getAndSetIp = async () => {
    const userIp = await getUserIp();
    setAtom(userIp);
  };

  getAndSetIp();
};

export const userDeviceAtom = atom<string>('');
userDeviceAtom.onMount = (setAtom) => {
  const os = getOS();
  setAtom(os);
};

export const userActionAtom = atom<string>('click');
export const targetPageAtom = atom<string>('');

export const userAnalysisDataAtom = atom<UserAnalysisData>((get) => {
  const brand = get(brandNameAtom);
  const product_id = get(productIdAtom);
  const device = get(userDeviceAtom);
  const action = get(userActionAtom);
  const target_page = get(targetPageAtom);
  const ip = get(userIpAtom);

  return {
    brand,
    product_id,
    device,
    action,
    target_page,
    ip,
  };
});

// export const termsAllCheckedAtom = atom(
//     (get) => {
//       const { personalInfo, preservationClothingValue, supportForDevelopers } =
//         get(temrsAgreedAtom);
//       return personalInfo && preservationClothingValue && supportForDevelopers;
//     },
//     (get, set) => {
//       set(temrsAgreedAtom, (prev) =>
//         prev.personalInfo ? initTermsAgreed : temrsAllAgreed
//       );
//     }
//   );
