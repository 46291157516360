import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { Navigator } from '@components/@shared';
import AnalysisPage from './AnalysisPage';

export default function LayoutPage() {
  return (
    <Container>
      <Main>
        <AnalysisPage>
          <Outlet />
        </AnalysisPage>
      </Main>
      <Navigator />
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  min-height: 100dvh;
  padding-bottom: 120px;
  background-color: ${(props) => props.theme.colors.white};
`;

const Main = styled.main`
  padding-bottom: 32px;
`;
