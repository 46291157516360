import { AES_KEY } from '@lib/apis/user/@core';
import CryptoJS from 'crypto-js';

/**
 * 복호화해주는 함수
 */
export const decrypt = (encryptedData: string, key: string | undefined) => {
  if (key === undefined) {
    throw new Error('key is invalid');
  }

  const bytes = CryptoJS.AES.decrypt(encryptedData, key);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

  return JSON.parse(decryptedData);
};
