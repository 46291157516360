import { atom } from 'jotai';
import { changeLanguage } from 'i18next';

export const languageAtom = atom<string>(
  localStorage.getItem('i18nextLng') ?? ''
);

export const languageAtomWithPersistence = atom(
  (get) => get(languageAtom),
  (get, set, lang?: string) => {
    if (lang) {
      set(languageAtom, lang);
      localStorage.setItem('i18nextLng', lang);
      changeLanguage(lang);
    } else {
      set(languageAtom, '');
      localStorage.removeItem('i18nextLng');
      changeLanguage('ko');
    }
  }
);

export const brandNameAtom = atom<string>('');
export const productIdAtom = atom<string>('');
