import { toast } from 'react-toastify';
import {
  ERROR_MESSAGE_DEFAULT,
  SUCCESS_MESSAGE_DEFAULT,
} from '@lib/constants/errorMessages';

export interface ResponseResult {
  msg: null | string;
  data: null | ImageErrorInfoData[] | ProductErrorInfoData[];
}

export interface ImageErrorInfoData {
  name: string;
  msg: string;
}

export interface ProductErrorInfoData {
  no: string;
  msg: string;
}

interface OptionParams {
  successMsg?: string;
  errorMsg?: string;
}

/**
 * 에러 메시지가 배열로 올 경우 문자열로 변환하여 toast 띄워주는 함수
 */
export const showMutationResult = (
  res: ResponseResult,
  options?: OptionParams
) => {
  // 실패 시
  if (res?.data && Array.isArray(res.data)) {
    const errorMessages = res.data.reduce(
      (acc: string, curr: any) =>
        acc + ' ' + `\n${curr.name || curr.no}번 제품: ${curr.msg}`,
      ''
    );
    toast.error(errorMessages || options?.errorMsg || ERROR_MESSAGE_DEFAULT);
    return;
  }

  // 성공 시
  alert(res?.msg || options?.successMsg || SUCCESS_MESSAGE_DEFAULT);
};
