import { useQuery } from '@tanstack/react-query';
import { userAxios } from './@core';
import { DppLevelFiveData } from '@customTypes/user';

/**
 * 제품 상세 조회 API
 */
const fetchProductDetail = async (
  productId: string | undefined
): Promise<DppLevelFiveData> => {
  const res = await userAxios.get(`/product/${productId}`);

  return res?.data;
};

export const useProductDetail = (productId: string | undefined) => {
  return useQuery({
    queryKey: ['productDetail'],
    queryFn: () => fetchProductDetail(productId),
    enabled: !!productId,
  });
};
