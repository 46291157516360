import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DEFAULT } from '@lib/constants/errorMessages';
import App from './App';
import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      // 5분
      staleTime: 300000,
      gcTime: 300000,
      throwOnError: true,
    },
    mutations: {
      onError: (error) => {
        console.log(error);
        if (!(error instanceof AxiosError)) return;

        const resErrorMsg =
          error.response?.data?.result?.msg || ERROR_MESSAGE_DEFAULT;

        // 에러 메세지가 배열로 오면 하나의 문자열로 합쳐주기
        const errorMessage = Array.isArray(resErrorMsg)
          ? resErrorMsg.reduce(
              (acc: string, curr: any) => acc + `\n${curr}`,
              ''
            )
          : resErrorMsg;

        toast.error(errorMessage);
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);
