import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface ImageCarouselProps {
  /**
   * img
   */
  children: React.ReactNode;
}

export default React.memo(function ImageCarousel({
  children,
}: ImageCarouselProps) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return <StyledSlider {...settings}>{children}</StyledSlider>;
});

const StyledSlider = styled(Slider as any)`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white};

  .slick-list {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: inherit;
  }

  .slick-slide > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .slick-slide img {
    width: auto;
    height: 100%;
    margin: 0 auto;
    object-fit: contain;
  }

  /* Indicator */
  ul.slick-dots {
    /* display: none !important; */
    position: static;
    height: 50px;

    & li {
      position: relative;
      top: 25%;
      width: 10px;
      height: 10px;
      transition: all 200ms cubic-bezier(0.22, 0.005, 0.055, 0.995);
    }

    & li.slick-active {
      width: 30px;
      height: 10px;
    }

    & li button {
      width: 100%;
      height: 100%;
      padding: 2px;
      border-radius: ${(props) => props.theme.borderRadiuses.full};
      background-color: ${(props) => props.theme.colors.gray100};
    }

    & li button:before {
      display: none;
    }

    & li.slick-active button {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      background-color: ${(props) => props.theme.colors.black};
    }
  }

  .slick-track {
    width: 100%;
    height: 100%;
  }

  /* 좌우로 넘기는 버튼 */
  .slick-arrow {
    width: 10%;
    max-width: 30px;
    height: 100%;
    opacity: 0;
    z-index: 1;

    &.slick-prev {
      left: 0;
    }

    &.slick-next {
      right: 0;
    }

    &::before {
      display: none;
    }
  }
`;
